import React, { Component } from "react";

class SideComponent extends Component {
    renderDots = () => {
        var totalDots = this.props.stepData.totalDots | 0;
        var dots = [];
        for (let index = 0; index < totalDots; index++) {
            if (this.props.stepData.currentStep === index) {
                dots.push(<div key={index} className="dot active" />);
            } else {
                dots.push(<div key={index} className="dot" />);
            }
        }
        return <>{dots}</>;
    };
    render() {
        return (
            <div
                className={
                    "side-component primary-color-bg " +
                    (this.props.stepData.currentStep === 3
                        ? "thankyou-component"
                        : "")
                }
            >
                <div className="thumb-holder">
                    <img
                        src={this.props.data.thumbImage}
                        alt=""
                        className="thumbnail"
                    />
                </div>
                <div className="side-holder">
                    <h3 className="white-text text-center side-heading">
                        {this.props.data.sideHeading}
                    </h3>
                    <p
                        className="side-content text-center"
                        dangerouslySetInnerHTML={{
                            __html: this.props.data.sideContent
                        }}
                    />
                </div>

                <div className="pagination-holder">
                    <div className="dots">{this.renderDots()}</div>
                </div>
            </div>
        );
    }
}

export default SideComponent;
