import React, { Component } from "react";

class RadioComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: undefined,
        };
    }

    updateChoice = (newIndex) => {
        this.setState({
            selectedIndex: newIndex,
        });
        this.props.handleInputChange(
            this.props.name,
            this.props.choices[newIndex]
        );
    };
    componentWillMount() {
        if (this.props.defaultValue !== undefined) {
            this.setState({
                selectedIndex: this.props.choices.indexOf(
                    this.props.defaultValue
                ),
            });
        }
    }

    renderRadios = (choices) => {
        var radios = [];
        choices.forEach((choiceValue, i) => {
            var choiceClass = this.state.selectedIndex === i ? " selected" : "";

            radios.push(
                <div
                    key={i}
                    className={"choice-holder" + choiceClass}
                    onClick={() => this.updateChoice(i)}
                >
                    <input
                        type="radio"
                        id={this.props.nam}
                        name={this.props.name}
                        value={choiceValue}
                        checked={this.state.selectedIndex === i}
                        className="form-control radio-control"
                        onChange={(e) => this.updateChoice(i)}
                    />
                    {this.props.iconLayout && (
                        <i
                            className={"fa choice-icon " + this.props.icons[i]}
                        />
                    )}
                    <span className="choice-label">{choiceValue}</span>
                </div>
            );
        });

        return radios;
    };
    render() {
        return (
            <div
                className={
                    "input-holder " + (this.props.hasError ? " has-error " : "")
                }
            >
                {this.props.iconLayout && (
                    <label htmlFor={this.props.name}>{this.props.label}</label>
                )}
                {this.renderRadios(this.props.choices)}
            </div>
        );
    }
}

export default RadioComponent;
