import React, { Component } from "react";
import LinkInputComponent from "./LinkInputComponent";

class LinksComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            links: ["", "", "", ""],
            errorMessage: "",
        };
    }

    saveToFormData = (name, value) => {
        var links = this.state.links;
        name = parseInt(name);
        links[name] = value;
        this.setState({
            links,
        });
    };

    nextStep = () => {
        if (this.state.links.length === 0 || this.state.links[0] === "") {
            this.setState({ errorMessage: "Please Submit at least one link." });
        } else {
            var linkJSON = JSON.stringify(this.state.links);
            this.props.nextStep({ links: linkJSON });
        }
    };

    componentDidMount() {
        var links = localStorage.getItem("links");
        if (links) {
            this.setState({ links: JSON.parse(links) });
        }
    }

    render() {
        return (
            <div className="main-component links-component">
                {/* Links Component */}
                <div className="links-holder">
                    <div className="description">
                        <p>
                            Please provide links to upto 4 of your performances
                            here. You can upload them to Google Drive, dropbox,
                            Youtube, Tik Tok or any other publicly available
                            platform and share the links here.
                        </p>
                    </div>
                    <div className="linkinput-wrapper">
                        {Object.keys(this.state.links).map((key, i) => (
                            <LinkInputComponent
                                key={i}
                                id={i}
                                defaultValue={this.state.links[key]}
                                handleInputChange={this.saveToFormData}
                            />
                        ))}
                    </div>
                </div>
                <div className="buttons-holder">
                    <button
                        onClick={this.props.prevStep}
                        className="white-text light-text pagination-button prev-button upper-text special-color-button"
                    >
                        Previous
                    </button>
                    <p className="error-message red-text">
                        {this.state.errorMessage}
                    </p>
                    <button
                        onClick={this.nextStep}
                        className="white-text light-text pagination-button next-button upper-text secondary-color-button"
                    >
                        Submit
                    </button>
                </div>
            </div>
        );
    }
}

export default LinksComponent;
