import React, { Component } from "react";
import InputComponent from "./InputComponent";

class FormComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            applicationData: {
                // facebook: "/",
                // twitter: "@",
                // instagram: "@",
            },
            errorFields: new Set(),
            errorMessage: "",
            applicationFields: [
                "name",
                "gender",
                "dob",
                "email",
                "mobile",
                "qualification",
                "institution",
                "current_location",
                "place_of_origin",
                "occupation",
                "organisation",
            ],
        };
    }

    componentWillMount() {
        var applicationData = this.state.applicationData;
        var allFields = this.state.applicationFields;
        var fieldValue;
        allFields.map((field) => {
            fieldValue = localStorage.getItem(field);
            if (fieldValue) {
                applicationData[field] = fieldValue;
            }
            return field;
        });
        this.setState({ applicationData });
    }

    nextStep = (e) => {
        e.preventDefault();
        const applicationData = this.state.applicationData;
        var errorFields = this.state.errorFields;
        const requiredKeys = [
            "name",
            "gender",
            "dob",
            "email",
            "mobile",
            "institution",
            "current_location",
            "place_of_origin",
            "qualification",
        ];
        // const comboFields = ["facebook", "twitter", "instagram"];
        requiredKeys.forEach((reqKey) => {
            if (!(applicationData[reqKey] && applicationData[reqKey] !== "")) {
                errorFields.add(reqKey);
            } else {
                errorFields.delete(reqKey);
            }
        });

        this.setState({ errorFields });
        if (errorFields.size === 0) {
            // var comboCount = comboFields.reduce(
            //     (ac, field) =>
            //         ac +
            //         (Object.keys(applicationData).includes(field) &&
            //         applicationData[field].length > 1
            //             ? 1
            //             : 0),
            //     0
            // );
            // if (comboCount < 2) {
            //     this.setState({
            //         errorMessage:
            //             "Please enter at least 2 social profile links",
            //     });
            // } else {
            this.setState({
                errorMessage: "",
            });
            this.props.nextStep(applicationData);
            // }
        } else {
            this.setState({
                errorMessage: "Please Fill all the required Fields.",
            });
        }
    };

    saveToFormData = (name, value) => {
        var prevAppData = this.state.applicationData;
        prevAppData[name] = value;
        this.setState({
            applicationData: prevAppData,
        });
    };

    render() {
        return (
            <>
                <div className="main-component">
                    <div className="form-holder">
                        <form className="form-component">
                            <h4>Basic Details</h4>

                            <InputComponent
                                defaultValue={this.state.applicationData.name}
                                showLabel={true}
                                type="text"
                                name="name"
                                label="Name"
                                iconLayout={false}
                                handleInputChange={this.saveToFormData}
                                hasError={this.state.errorFields.has("name")}
                            />
                            <InputComponent
                                defaultValue={this.state.applicationData.gender}
                                showLabel={true}
                                type="radio"
                                name="gender"
                                label="Gender"
                                iconLayout={true}
                                choices={["Male", "Female"]}
                                icons={["fa-mars", "fa-venus"]}
                                handleInputChange={this.saveToFormData}
                                hasError={this.state.errorFields.has("gender")}
                            />
                            <InputComponent
                                defaultValue={this.state.applicationData.dob}
                                showLabel={true}
                                type="date"
                                name="dob"
                                label="Date of Birth"
                                iconLayout={false}
                                handleInputChange={this.saveToFormData}
                                hasError={this.state.errorFields.has("dob")}
                            />
                            <InputComponent
                                defaultValue={this.state.applicationData.email}
                                showLabel={true}
                                type="email"
                                name="email"
                                label="Email"
                                iconLayout={false}
                                handleInputChange={this.saveToFormData}
                                hasError={this.state.errorFields.has("email")}
                            />
                            <InputComponent
                                defaultValue={this.state.applicationData.mobile}
                                showLabel={true}
                                type="tel"
                                name="mobile"
                                label="Mobile"
                                iconLayout={false}
                                handleInputChange={this.saveToFormData}
                                hasError={this.state.errorFields.has("mobile")}
                            />
                            <div className="social-holder">
                                <h4 className="section-heading">
                                    Educational Qualifications
                                </h4>
                                <InputComponent
                                    defaultValue={
                                        this.state.applicationData.qualification
                                    }
                                    showLabel={true}
                                    type="text"
                                    name="qualification"
                                    label="Qualification*"
                                    handleInputChange={this.saveToFormData}
                                    hasError={this.state.errorFields.has(
                                        "qualification"
                                    )}
                                />
                                <InputComponent
                                    defaultValue={
                                        this.state.applicationData.institution
                                    }
                                    showLabel={true}
                                    type="text"
                                    name="institution"
                                    label="Institution name*"
                                    handleInputChange={this.saveToFormData}
                                    hasError={this.state.errorFields.has(
                                        "institution"
                                    )}
                                />
                                <InputComponent
                                    defaultValue={
                                        this.state.applicationData
                                            .current_location
                                    }
                                    showLabel={true}
                                    type="text"
                                    name="current_location"
                                    label="Current Location*"
                                    handleInputChange={this.saveToFormData}
                                    hasError={this.state.errorFields.has(
                                        "current_location"
                                    )}
                                />
                                <InputComponent
                                    defaultValue={
                                        this.state.applicationData
                                            .place_of_origin
                                    }
                                    showLabel={true}
                                    type="text"
                                    name="place_of_origin"
                                    label="Place of Origin*"
                                    handleInputChange={this.saveToFormData}
                                    hasError={this.state.errorFields.has(
                                        "place_of_origin"
                                    )}
                                />
                                <InputComponent
                                    defaultValue={
                                        this.state.applicationData.occupation
                                    }
                                    showLabel={true}
                                    type="text"
                                    name="occupation"
                                    label="Occupation"
                                    handleInputChange={this.saveToFormData}
                                    hasError={this.state.errorFields.has(
                                        "occupation"
                                    )}
                                />
                                <InputComponent
                                    defaultValue={
                                        this.state.applicationData.organisation
                                    }
                                    showLabel={true}
                                    type="text"
                                    name="organisation"
                                    label="Organisation"
                                    handleInputChange={this.saveToFormData}
                                    hasError={this.state.errorFields.has(
                                        "organisation"
                                    )}
                                />
                            </div>

                            <div className="buttons-holder">
                                <p className="error-message red-text">
                                    {this.state.errorMessage}
                                </p>
                                <button
                                    onClick={this.nextStep}
                                    className="white-text light-text pagination-button next-button upper-text secondary-color-button"
                                >
                                    Next
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        );
    }
}

export default FormComponent;
