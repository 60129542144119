import React, { Component } from "react";
import RadioComponent from "./RadioComponent";

class InputComponent extends Component {
    saveToFormData = e => {
        this.props.handleInputChange(e.target.name, e.target.value);
    };

    render() {
        if (this.props.type === "text") {
            if (this.props.iconLayout) {
                return (
                    <div
                        className={
                            (this.props.hasError ? "has-error" : "") +
                            " input-holder icon-layout "
                        }
                    >
                        {this.props.showLabel ? (
                            <label className="d-none" htmlFor={this.props.name}>
                                {this.props.label}
                            </label>
                        ) : (
                            <></>
                        )}
                        <i className={"fa input-icon " + this.props.icons[0]} />
                        <input
                            defaultValue={this.props.defaultValue}
                            onChange={this.saveToFormData}
                            className="form-control"
                            id={this.props.name}
                            type={this.props.type}
                            name={this.props.name}
                        />
                    </div>
                );
            } else {
                return (
                    <div
                        className={
                            (this.props.hasError ? "has-error" : "") +
                            " input-holder "
                        }
                    >
                        {this.props.showLabel ? (
                            <label htmlFor={this.props.name}>
                                {this.props.label}
                            </label>
                        ) : (
                            <></>
                        )}
                        <input
                            defaultValue={this.props.defaultValue}
                            onChange={this.saveToFormData}
                            className="form-control"
                            id={this.props.name}
                            type={this.props.type}
                            name={this.props.name}
                        />
                    </div>
                );
            }
        } else if (this.props.type === "email") {
            return (
                <div
                    className={
                        (this.props.hasError ? "has-error" : "") +
                        " input-holder "
                    }
                >
                    {this.props.showLabel ? (
                        <label htmlFor={this.props.name}>
                            {this.props.label}
                        </label>
                    ) : (
                        <></>
                    )}
                    <input
                        defaultValue={this.props.defaultValue}
                        onChange={this.saveToFormData}
                        className="form-control"
                        id={this.props.name}
                        type={this.props.type}
                        name={this.props.name}
                    />
                </div>
            );
        } else if (this.props.type === "tel") {
            return (
                <div
                    className={
                        (this.props.hasError ? "has-error" : "") +
                        " input-holder "
                    }
                >
                    {this.props.showLabel ? (
                        <label htmlFor={this.props.name}>
                            {this.props.label}
                        </label>
                    ) : (
                        <></>
                    )}
                    <input
                        defaultValue={this.props.defaultValue}
                        onChange={this.saveToFormData}
                        className="form-control"
                        id={this.props.name}
                        type={this.props.type}
                        name={this.props.name}
                    />
                </div>
            );
        } else if (this.props.type === "date") {
            return (
                <div
                    className={
                        (this.props.hasError ? "has-error" : "") +
                        " input-holder "
                    }
                >
                    {this.props.showLabel ? (
                        <label htmlFor={this.props.name}>
                            {this.props.label}
                        </label>
                    ) : (
                        <></>
                    )}
                    <input
                        defaultValue={this.props.defaultValue}
                        onChange={this.saveToFormData}
                        className="form-control"
                        id={this.props.name}
                        type={this.props.type}
                        name={this.props.name}
                    />
                    <i className="fa fa-calendar calendar-icon" />
                </div>
            );
        } else if (this.props.type === "radio") {
            return <RadioComponent {...this.props} />;
        }
    }
}

export default InputComponent;
