import React, { Component } from "react";
import "./assets/css/style.min.css";
import chai from "./assets/img/chai.png";
import SideComponent from "./components/SideComponent";
import MainComponent from "./components/MainComponent";
import actorIcon from "./assets/img/Icon-Actor.png";
import DirectorIcon from "./assets/img/Icon-Director.png";
import EditorIcon from "./assets/img/Icon-Editor.png";
import CinematographerIcon from "./assets/img/Icon-EditorCopy.png";
import ExecutivePIcon from "./assets/img/Icon-ExecutiveP.png";
import MusicDirectorIcon from "./assets/img/Icon-MusicDirector.png";
import OthersIcon from "./assets/img/Icon-Others.png";
import WriterIcon from "./assets/img/Icon-Writer.png";
import GraphicDesignerIcon from "./assets/img/Icon-GraphicDesigner.png";
import config from "./config";
import ThankyouComponent from "./components/ThankyouComponent";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stepData: {
                totalDots: 3,
                currentStep: 0,
            },
            applicationData: {},
            applicationFields: [
                [
                    "name",
                    "gender",
                    "dob",
                    "email",
                    "mobile",
                    "qualification",
                    "institution",
                    "current_location",
                    "place_of_origin",
                    "occupation",
                    "organisation",
                    // "facebook",
                    // "twitter",
                    // "instagram",
                ],
                ["role"],
                ["links"],
            ],
            contentFields: {
                0: {
                    sideData: {
                        thumbImage: chai,
                        sideHeading: "Let's get brewing",
                        sideContent:
                            "We can’t wait  to do some kickass work with you. But first, let us know a little about you.",
                    },
                    mainData: {
                        thumb: chai,
                    },
                },
                1: {
                    sideData: {
                        thumbImage: chai,
                        sideHeading: "Who are you?",
                        sideContent:
                            "Everyone’s got a role here. What is the one you want to play?",
                    },
                    mainData: {
                        roles: [
                            {
                                roleKey: "actor",
                                roleName: "Actor / Performer",
                                roleIcon: actorIcon,
                                roleContent: `“I love acting. It is so much more real than life.” <br/> -Oscar Wilde <br/> <br/>  
                                We’re always looking for fresh faces. You can be our next.`,
                            },
                            {
                                roleKey: "director",
                                roleName: "Director",
                                roleIcon: DirectorIcon,
                                roleContent: `“If a million people see my movie, I hope they see a million different perspectives.” <br/> -Quentin Tarantino <br/> <br/>
                                Do you feel alive shouting “Lights, camera, action, cut”? sign up here.`,
                            },
                            {
                                roleKey: "editor",
                                roleName: "Video Editor",
                                roleIcon: EditorIcon,
                                roleContent: `“Everything that precedes editing is merely a way of producing film to edit.”
                                <br/> -Stanley Kubrick
                                <br/><br/>
                                When you can be brutal enough to cut through our work, Welcome aboard.`,
                            },
                            {
                                roleKey: "dop",
                                roleName: "Cinematographer",
                                roleIcon: CinematographerIcon,
                                roleContent: `“Cinematography is writing with images in movement and with sounds.” 
                                <br/> -Robert Bresson
                                <br/><br/>
                                When you can light up our work. Let’s get lit.`,
                            },
                            {
                                roleKey: "producer",
                                roleName: "Executive Producer",
                                roleIcon: ExecutivePIcon,
                                roleContent: `“Creating something out of thin air is easy. It’s finding the air that’s hard.”
                                <br/> -Asher Trotter
                                <br/><br/>
                                If you can get shit shoot done, Let’s do it.`,
                            },
                            {
                                roleKey: "designer",
                                roleName: "Graphic Designer",
                                roleIcon: GraphicDesignerIcon,
                                roleContent: `“Design is where science and art break even.”
                                <br/> -Robin Mathew
                                <br/><br/>
                                When you can compress a .Mp4 to .jpg, join us. .PSd: you’re our designer.`,
                            },
                            {
                                roleKey: "music",
                                roleName: "Singers / Musicians",
                                roleIcon: MusicDirectorIcon,
                                roleContent: `“Music begins where the possibilities of language end.”
                                <br/> -Jean Sibelius
                                <br/><br/>
                                If you can perfectly tune into director’s mind, you’re welcome.`,
                            },
                            {
                                roleKey: "writer",
                                roleName: "Writer",
                                roleIcon: WriterIcon,
                                roleContent: `“I didn't go to film school, I went to films”
                                <br/> -Quentin Tarantino
                                <br/><br/>
                                That crazy idea still giving you sleepless nights? Share with us.`,
                            },
                            {
                                roleKey: "other",
                                roleName: "Others",
                                roleIcon: OthersIcon,
                                roleContent: `“You must stay drunk on writing, so reality cannot destroy you.”
                                <br/> -Ray Bradbury
                               <br/><br/>If you think none of tye mentioned roles decribe well enough, go ahead and show us some of your work.`,
                            },
                        ],
                    },
                },
                2: {
                    sideData: {
                        thumbImage: actorIcon,
                        sideHeading: "Actor / Performer",
                        sideContent: `“I love acting. It is so much more real than life.”  -Oscar Wilde <br/> <br/>  
                        We’re always looking for fresh faces. You can be our next.`,
                    },
                    mainData: {
                        thumb: chai,
                    },
                },
            },
        };
    }

    nextStep = (applicationData) => {
        const dataUpdateToApp = Object.keys(applicationData).map((key) => {
            return new Promise((resolve, reject) => {
                resolve(this.saveApplicationData(key, applicationData[key]));
            });
        });
        Promise.all(dataUpdateToApp).then((result) => {
            var stepData = this.state.stepData;
            stepData.currentStep += 1;
            if (stepData.currentStep === 2) {
                var contentFields = this.state.contentFields;
                contentFields[2].sideData = {
                    thumbImage:
                        contentFields[1].mainData.roles[
                            this.state.applicationData.roleIndex
                        ].roleIcon,
                    sideHeading:
                        contentFields[1].mainData.roles[
                            this.state.applicationData.roleIndex
                        ].roleName,
                    sideContent:
                        contentFields[1].mainData.roles[
                            this.state.applicationData.roleIndex
                        ].roleContent,
                };
            }
            if (stepData.currentStep === 3) {
                this.submitEverything();
            }
            this.setState({ stepData });
        });
    };

    submitEverything = () => {
        var appData = this.state.applicationData;
        if (Array.isArray(appData.links)) {
            appData.links.forEach((link, i) => {
                appData["link_" + i] = link;
            });
        } else {
            appData.links = JSON.parse(appData.links) || [];
            appData.links.forEach((link, i) => {
                appData["link_" + i] = link;
            });
        }

        if (appData.answers) {
            if (Array.isArray(appData.answers)) {
                appData.answers.forEach((answer, i) => {
                    appData["answer_" + (i + 1)] = answer;
                });
            } else {
                appData.answers = JSON.parse(appData.answers) || [];
                appData.answers.forEach((answer, i) => {
                    appData["answer_" + (i + 1)] = answer;
                });
            }
        }
        var gsUrl = new URL(
            config[
                this.state.contentFields[1].mainData.roles[appData.roleIndex]
                    .roleKey
            ]
                ? config[
                      this.state.contentFields[1].mainData.roles[
                          appData.roleIndex
                      ].roleKey
                  ].submit_url
                : "https://script.google.com/macros/s/AKfycbw_uaR88hpVDsXE9ofSXSsKEm8an-tJZZK-twgM5sLo5-q4GDLXvcLVU4Xw_bjooV5P/exec"
        );
        delete appData.links;
        delete appData.roleIndex;
        new Promise((resolve, reject) => {
            Object.keys(appData).forEach((key) => {
                if (appData[key] && appData[key] !== "")
                    gsUrl.searchParams.append(key, appData[key]);
            });

            resolve(gsUrl);
        }).then((gsUrl) => {
            fetch(gsUrl).then((r) => console.log(r));
        });

        // this.resetStorage();
    };

    resetStorage = () => {
        this.setState({ applicationData: {} });
        localStorage.clear();
    };

    componentDidMount() {
        var applicationData = this.state.applicationData;
        var allFields = this.state.applicationFields.reduce(
            (ac, field) => ac.concat(field),
            []
        );
        allFields.map((field) => {
            if (field === "links") {
                applicationData[field] = JSON.parse(
                    localStorage.getItem(field)
                );
            } else {
                applicationData[field] = localStorage.getItem(field);
            }
            return field;
        });

        var contentFields = this.state.contentFields;
        contentFields[2].sideData = {
            thumbImage:
                contentFields[1].mainData.roles[
                    localStorage.getItem("roleIndex") || 0
                ].roleIcon,
            sideHeading:
                contentFields[1].mainData.roles[
                    localStorage.getItem("roleIndex") || 0
                ].roleName,
            sideContent:
                contentFields[1].mainData.roles[
                    localStorage.getItem("roleIndex") || 0
                ].roleContent,
        };
        this.setState({ applicationData });
    }

    saveApplicationData = (key, value) => {
        var ret = {};
        localStorage.setItem(key, value);
        var applicationData = this.state.applicationData;
        applicationData[key] = value;
        this.setState({ applicationData });
        ret[key] = value;
        return ret;
    };

    prevStep = () => {
        if (this.state.stepData.currentStep !== 0) {
            var stepData = this.state.stepData;
            stepData.currentStep -= 1;
            this.setState({ stepData });
        }
    };

    render() {
        const { sideData, mainData } = {
            ...this.state.contentFields[this.state.stepData.currentStep],
        };

        return (
            <div className="recruit-app">
                {this.state.stepData.currentStep === 3 ? (
                    <ThankyouComponent />
                ) : (
                    <>
                        <SideComponent
                            stepData={this.state.stepData}
                            data={sideData}
                        />
                        <MainComponent
                            nextStep={this.nextStep}
                            prevStep={this.prevStep}
                            stepData={this.state.stepData}
                            data={mainData}
                            applicationData={this.state.applicationData}
                        />
                    </>
                )}
            </div>
        );
    }
}

export default App;
