import React, { Component } from "react";

class LinkInputComponent extends Component {
    render() {
        return (
            <div>
                <div className={" input-holder icon-layout "}>
                    <label htmlFor={this.props.name}>
                        Link {this.props.id}
                    </label>
                    <i className={"fa input-icon fa-link"} />
                    <input
                        defaultValue={this.props.defaultValue}
                        onChange={this.props.handleInputChange}
                        className="form-control"
                        name={this.props.id}
                    />
                </div>
            </div>
        );
    }
}

export default LinkInputComponent;
