import React from "react";

const IntroComponent = ({ errorMessage, nextStep, prevStep }) => {
    return (
        <div className="intro-section">
            <div className="question-intro">
                <p className="text-center">
                    We would like to know more about you before we can sit and
                    have a cup of chai. You will need to answer a series of
                    questions that help us analyse your aptitude for the
                    selected role.
                </p>
                <p className="text-center">
                    Answer them as originally and authentically as possible. You
                    can consider this your first round of interview.
                </p>
            </div>
            <div className="buttons-holder">
                <button
                    onClick={nextStep}
                    className="white-text light-text pagination-button next-button upper-text secondary-color-button"
                >
                    Start
                </button>
                <p className="error-message red-text">{errorMessage}</p>
                <button
                    onClick={prevStep}
                    className="white-text light-text pagination-button prev-button  special-color-button"
                >
                    Edit my details
                </button>
            </div>
        </div>
    );
};

export default IntroComponent;
