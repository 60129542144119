import React, { Component } from "react";
import config from "../config";
import IntroComponent from "./questions/IntroComponent";
import SingleQuestionComponent from "./questions/SingleQuestionComponent";
import LinkInputComponent from "./LinkInputComponent";
import InputComponent from "./InputComponent";

class QuestionsComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentQuestion: -1,
            answers: ["Full-time Writer"],
            errorMessage: "",
        };
    }

    start = () => {
        this.setState({
            currentQuestion: 0,
        });
    };

    end = () => {
        var c = this.state.currentQuestion;
        localStorage.setItem("answer_" + c, this.state.answers[c]);
        this.props.nextStep({
            answers: this.state.answers,
        });
    };

    nextQuestion = () => {
        var c = this.state.currentQuestion;
        localStorage.setItem("answer_" + c, this.state.answers[c]);
        this.setState({
            currentQuestion: this.state.currentQuestion + 1,
        });
    };

    prevQuestion = () => {
        this.setState({
            currentQuestion: this.state.currentQuestion - 1,
        });
    };

    setAnswer = (e, qIndex = null) => {
        var answer = e.target.value;
        var answers = [...this.state.answers];
        answers[qIndex != null ? qIndex : this.state.currentQuestion] = answer;
        this.setState({
            answers,
        });
    };

    renderQuestion = (qIndex) => {
        const roleKey = localStorage.getItem("roleKey");
        const question = config[roleKey].questions[qIndex];
        const totalQuestions = config[roleKey].questions.length;

        return (
            <SingleQuestionComponent
                questionIndex={qIndex + 1}
                totalQuestions={totalQuestions}
                question={question}
                answer={this.state.answers[qIndex] || ""}
                nextQuestion={
                    qIndex < totalQuestions - 1 ? this.nextQuestion : this.end
                }
                prevQuestion={this.prevQuestion}
                setAnswer={this.setAnswer}
                errorMessage={this.state.errorMessage}
            >
                {question.type === "links" ? (
                    <>
                        <LinkInputComponent
                            id={1}
                            defaultValue={this.state.answers[qIndex]}
                            handleInputChange={(e) => this.setAnswer(e, qIndex)}
                        />
                        <LinkInputComponent
                            id={2}
                            defaultValue={this.state.answers[qIndex + 1]}
                            handleInputChange={(e) =>
                                this.setAnswer(e, qIndex + 1)
                            }
                        />
                        <LinkInputComponent
                            id={3}
                            defaultValue={this.state.answers[qIndex + 2]}
                            handleInputChange={(e) =>
                                this.setAnswer(e, qIndex + 2)
                            }
                        />
                        <LinkInputComponent
                            id={4}
                            defaultValue={this.state.answers[qIndex + 3]}
                            handleInputChange={(e) =>
                                this.setAnswer(e, qIndex + 3)
                            }
                        />
                    </>
                ) : question.type === "radio" ? (
                    <>
                        <div className={question.cssClassName}>
                            <InputComponent
                                defaultValue={
                                    this.state.answers[qIndex] ||
                                    question.options[0]
                                }
                                showLabel={false}
                                type="radio"
                                name={qIndex}
                                label={question.question}
                                iconLayout={false}
                                choices={question.options}
                                handleInputChange={(name, value) => {
                                    var prevAppData = this.state.answers;
                                    prevAppData[name] = value;
                                    this.setState({
                                        answers: prevAppData,
                                    });
                                }}
                            />
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </SingleQuestionComponent>
        );
    };

    componentDidMount() {
        const roleKey = localStorage.getItem("roleKey");
        if (roleKey) {
            var length = config[roleKey].questions.length;
            var answers = [];
            for (let i = 0; i < length; i++) {
                answers[i] =
                    localStorage.getItem("answer_" + i) ||
                    this.state.answers[i] ||
                    "";
            }
            this.setState({
                answers,
            });
        }
    }

    render() {
        return (
            <div className="main-component questions-component">
                {/* Questions Component */}
                {this.state.currentQuestion === -1 ? (
                    <IntroComponent
                        errorMessage={this.state.errorMessage}
                        nextStep={this.start}
                        prevStep={this.props.prevStep}
                    />
                ) : (
                    <>{this.renderQuestion(this.state.currentQuestion)}</>
                )}
            </div>
        );
    }
}

export default QuestionsComponent;
