import React from "react";
import config from "../../config";
import { countWords } from "../../utils";

const SingleQuestionComponent = ({
    question,
    setAnswer,
    answer,
    prevQuestion,
    nextQuestion,
    questionIndex,
    totalQuestions,
    errorMessage,
    children,
}) => {
    return (
        <div className="question-section">
            <div className="progress-bar">
                {progressBlocks(totalQuestions, questionIndex).map((block) => {
                    return block;
                })}
            </div>
            <div className="question-holder">
                <div
                    className="question regular-text"
                    dangerouslySetInnerHTML={{ __html: question.question }}
                ></div>
                <div className="question-image">
                    <img src={question.image} alt="" />
                </div>
                <div className="answer-holder">
                    {question.type === "number" ? (
                        <input
                            className="answer form-control input-number"
                            value={answer}
                            onChange={setAnswer}
                            type="number"
                        />
                    ) : question.type === "links" ||
                      question.type === "radio" ? (
                        <>{children}</>
                    ) : (
                        <>
                            <textarea
                                placeholder="Start Writing Here"
                                className="answer form-control"
                                value={answer}
                                onChange={setAnswer}
                            ></textarea>
                            <p className="sub-text">
                                {countWords(answer)} /{" "}
                                {question.maxWords || config.MAX_WORDS} words
                                max.
                            </p>
                        </>
                    )}
                </div>
            </div>

            <div className="buttons-holder">
                <button
                    onClick={prevQuestion}
                    className="white-text light-text pagination-button prev-button upper-text special-color-button"
                >
                    {questionIndex === 1 ? "Back" : "Previous"}
                </button>
                <p className="error-message red-text">{errorMessage}</p>
                <button
                    disabled={countWords(answer) < config.MIN_WORDS}
                    onClick={nextQuestion}
                    className={
                        "white-text light-text pagination-button next-button upper-text secondary-color-button " +
                        (countWords(answer) < config.MIN_WORDS
                            ? "disabled"
                            : "")
                    }
                >
                    {questionIndex === totalQuestions ? "Submit" : "Next"}
                </button>
            </div>
        </div>
    );
};

const progressBlocks = (count, index) => {
    const progress = [];
    for (let i = 0; i < count; i++) {
        progress.push(
            <div
                key={i}
                className={
                    "block " +
                    (index === i + 1
                        ? "current"
                        : i < index - 1
                        ? "previous"
                        : "")
                }
                style={{
                    width: `calc((100% / ${count}) - 5px)`,
                }}
            ></div>
        );
    }
    return progress;
};

export default SingleQuestionComponent;
