import React, { Component } from "react";
import FormComponent from "./FormComponent";
import RoleComponent from "./RoleComponent";
import QuestionsComponent from "./QuestionsComponent";
import config from "../config";
import LinksComponent from "./LinksComponent";

class MainComponent extends Component {
    render() {
        if (this.props.stepData.currentStep === 2) {
            if (
                this.props.applicationData.roleKey &&
                config[this.props.applicationData.roleKey]
            ) {
                return <QuestionsComponent {...this.props} />;
            } else {
                return <LinksComponent {...this.props} />;
            }
        } else if (this.props.stepData.currentStep === 1) {
            return <RoleComponent {...this.props} />;
        } else if (this.props.stepData.currentStep === 0) {
            return <FormComponent {...this.props} />;
        } else {
            return <></>;
        }
    }
}

export default MainComponent;
