import React, { Component } from "react";
import chai from "../assets/img/chai.png";

class ThankyouComponent extends Component {
    render() {
        return (
            <div
                className={"side-component primary-color-bg thankyou-component"}
            >
                <div className="thumb-holder">
                    <img src={chai} alt="" className="thumbnail" />
                </div>
                <div className="side-holder">
                    <h3 className="white-text text-center side-heading">
                        Thank You
                    </h3>
                    <p className="side-content text-center">
                        We have received your application. Please wait while we
                        are actively looking into your profile and submissions.
                        Our team will get in touch with you as soon as possible.
                    </p>
                </div>
            </div>
        );
    }
}

export default ThankyouComponent;
