import writerimage from "./assets/img/writer-new.png";

const config = {
    writer: {
        questions: [
            {
                question: "Which type of role are you applying for?",
                type: "radio",
                options: [
                    "Full-time Writer",
                    "Part-time writer while Working / Studying",
                ],
                cssClassName: "apply-type-radio-holder",
            },
            {
                question:
                    "Tell us about yourself – your interests, passion, inspiration etc.",
                maxWords: 100,
            },
            {
                question:
                    "Do you have any ideas/scripts you have thought of/written for CB? Please list them out here briefly.",
            },
            {
                question:
                    "Pick up a real scenario from your life and write a one-page script on it? (Express a dramatic incident from your life in a cinematic way)",
            },
            {
                question:
                    "Pick your favourite Movie & write a one pager to convince us on why we should watch it.",
            },
            {
                question:
                    "Read the following scenario and write a one-page dialogue version for the following scene. <br/><p>Surya began working as a writer, resigning from his job without informing his parents. One day, he woke up to find his father staring at him, holding a script in his hand and his name on it. <br/>How will the conversation unfold from there? Write a dialogue version of the scene, concluding with an ending of your choice.</p>",
            },
            {
                question:
                    "Take a look at the image below and construct a story.",
                image: writerimage,
            },
            {
                question:
                    "Please list links of your prior work (if any) in the space of writing/Directing.",
                type: "links",
            },
        ],
        submit_url:
            "https://script.google.com/macros/s/AKfycbxUfUXHMe28YHyYLHe9SVbb6gO-cENjF3k8VVoGVTr9zZkqCCi7uFbbsePzeg_RZ-I4/exec",
    },
    director: {
        questions: [
            { question: "Tell us something about yourself." },
            { question: "Why do you want to join Chai Bisket?" },
            {
                question:
                    "Do you have any prior work experience? Please mention.",
            },
            {
                question:
                    "Write a short story/synopsis of a story in under one page (or 2000 words).",
            },
            // {
            //     question:
            //         "Take a look at the image below and construct a story",
            // },
            {
                question:
                    "Please share the links of your previous works (if any).",
                type: "links",
            },
        ],
        submit_url:
            "https://script.google.com/macros/s/AKfycbyma5r1Z0GtUvGo1ZQzloBUHjoutI42Z1BhUhIOy7woxFIUSWzZSx_drcSFISejRt6A/exec",
    },
    editor: {
        questions: [
            { question: "Tell us something about yourself." },
            { question: "Why do you want to join Chai Bisket?" },
            {
                question:
                    "Do you have any prior work experience? Please mention.",
            },
            {
                question:
                    "Please share the links of your previous works (if any).",
                type: "links",
            },
        ],
        submit_url:
            "https://script.google.com/macros/s/AKfycby1Vr2oBO3P0M6SUfSaWtKQ1no6NZJvFR2RmwAQ1gEZwvRBracezhiaJGWPr4Yh0RIc/exec",
    },
    actor: {
        questions: [
            { question: "Tell us something about yourself." },
            { question: "Why do you want to join Chai Bisket?" },
            { question: "Why do you want to become an actor?" },
            { question: "Height (in Feet)", type: "number" },
            { question: "Weight (in Kg)", type: "number" },
            {
                question:
                    "Please share the links to your previous works (audition videos, etc.).",
                type: "links",
            },
        ],
        submit_url:
            "https://script.google.com/macros/s/AKfycbw8_IkQnCe4uM-zqKN4-MrZK3NX6_-CuJOUmKhy59qYQmXASNkhLSd8hQR9RTKNOFpP/exec",
    },
    dop: {
        questions: [
            { question: "Tell us something about yourself." },
            { question: "Why do you want to join Chai Bisket?" },
            {
                question:
                    "Do you have any prior work experience? Please mention.",
            },
            {
                question:
                    "Please share the links of your previous works (if any).",
                type: "links",
            },
        ],
        submit_url:
            "https://script.google.com/macros/s/AKfycbzo6M3hxDaiZQkyUFsdwNZIhzxb0nQVsu30enV7uSj_l-r4ta4AvyXJIWEUA077OhwN/exec",
    },
    producer: {
        questions: [
            { question: "Tell us something about yourself." },
            { question: "Why do you want to join Chai Bisket?" },
            {
                question:
                    "Do you have any prior work experience? Please mention.",
            },
        ],
        submit_url:
            "https://script.google.com/macros/s/AKfycbzfDgYmM12hGPJcyNIyXT7_gmfgWP9ogkURyhWDDJKN3lerLkA4H6OkkZRtVM4bq113/exec",
    },
    designer: {
        questions: [
            { question: "Tell us something about yourself." },
            { question: "Why do you want to join Chai Bisket?" },
            {
                question:
                    "Do you have any prior work experience? Please mention.",
            },
            { question: "Please design a Title logo for “Middle Bench Ravi”." },
            {
                question: "Please share the links of your previous designs.",
                type: "links",
            },
        ],
        submit_url:
            "https://script.google.com/macros/s/AKfycbzSh1GxsjujUq_veha0DFZMBh98D4tfFL8ze_enMC63YERf0WLZuX2VwzVfLcVW9pCW/exec",
    },
    music: {
        questions: [
            {
                question: "Please share links of your previous works.",
                type: "links",
            },
        ],
        submit_url:
            "https://script.google.com/macros/s/AKfycbx-UEOwRA_P0CnavIqJLBfMqvVrNS-RnuYJcOr6gkf1gQN5s7stx5eGpcKoRJ1oj6CE/exec",
    },
    other: {
        questions: [
            {
                question:
                    "Please let us know through what role you can contribute Chaibisket’s Growth Journey.",
            },
            {
                question: "Please share links of your previous works.",
                type: "links",
            },
        ],
        submit_url:
            "https://script.google.com/macros/s/AKfycbw_uaR88hpVDsXE9ofSXSsKEm8an-tJZZK-twgM5sLo5-q4GDLXvcLVU4Xw_bjooV5P/exec",
    },
    MAX_WORDS: 300,
    MIN_WORDS: 1,
};

export default config;
