import React, { Component } from "react";
import tick from "../assets/img/check-mark.png";

class RoleComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRoleIndex: undefined,
            errorMessage: "",
        };
    }

    selectRole = (key) => {
        this.setState({ selectedRoleIndex: key });
    };

    nextStep = () => {
        if (this.state.selectedRoleIndex !== undefined) {
            const appData = {
                role: this.props.data.roles[this.state.selectedRoleIndex]
                    .roleName,
                roleIndex: this.state.selectedRoleIndex,
                roleKey: this.props.data.roles[this.state.selectedRoleIndex]
                    .roleKey,
            };
            this.props.nextStep(appData);
        } else {
            this.setState({ errorMessage: "Please Select a Role" });
        }
    };

    componentDidMount() {
        var role = localStorage.getItem("roleIndex");
        if (role) {
            this.setState({
                selectedRoleIndex: parseInt(role),
            });
        }
    }

    render() {
        return (
            <>
                <div className="main-component role-component">
                    {/* Main Component */}
                    <div className="role-holder">
                        <div className="roles">
                            {this.props.data.roles.map((role, key) => (
                                <div
                                    className={
                                        "role " +
                                        (this.state.selectedRoleIndex === key
                                            ? "selected"
                                            : "")
                                    }
                                    key={key}
                                    onClick={(e) => this.selectRole(key)}
                                >
                                    <img
                                        src={role.roleIcon}
                                        alt=""
                                        className="role-icon"
                                    />
                                    <h5 className="text-center role-name">
                                        {role.roleName}
                                    </h5>
                                    <div className="tick">
                                        <img src={tick} alt={tick} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="buttons-holder">
                        <button
                            onClick={this.props.prevStep}
                            className="white-text light-text pagination-button prev-button upper-text special-color-button"
                        >
                            Previous
                        </button>
                        <p className="error-message red-text">
                            {this.state.errorMessage}
                        </p>
                        <button
                            onClick={this.nextStep}
                            className="white-text light-text pagination-button next-button upper-text secondary-color-button"
                        >
                            Next
                        </button>
                    </div>
                </div>
            </>
        );
    }
}

export default RoleComponent;
